import React from "react";
import {graphql, Link} from "gatsby";

import AdvertCard from "src/components/AdvertCard";
import Column from "src/components/Column";
import ToggleSwitch from "src/components/ToggleSwitch";

import {withSEO} from "src/hoc/SEO";
import {pages} from "src/config";

import css from "./index.module.scss";
import Img from "gatsby-image";


class SalesAndRentalsPage extends React.Component {
    state = {
        activeSwitch: "leftOption",
        adverts: []
    };
    

    componentDidMount() {
        const advertType = this.state.activeSwitch === "leftOption" ? "for-sale" : "for-hire";
        const adverts = this.getAdverts(advertType);
        this.setState({adverts});
    }
    

    getAdverts(advertType) {
        return this.props.data.allSalesRentalsJson.edges.reduce((result, {node}) => {
            if (node.featureImage && node.featureImage.relativeDirectory.includes(advertType)) {
                result.push(node);
            }
            return result;
        }, []);
    }
    

    handleOptionChange({target: {name, value}}) {
        const adverts = this.getAdverts(value === "leftOption" ? "for-sale" : "for-hire");
        this.setState({[name]: value, adverts});
    };
    

    render() {
        const {activeSwitch, adverts} = this.state;
        return (
            <main>
                <Column direction="cc">
                    <Link to='/' className={css.logoImg}>
                        <Img fluid={this.props.data.logo.childImageSharp.fluid}/>
                    </Link>
                    <ToggleSwitch activeSwitch={activeSwitch} onChange={this.handleOptionChange.bind(this)}
                                  leftOption="For Sale" rightOption="For Hire"/>
                    <Column direction={adverts.length ? "tl" : "cc"} className={css.adverts}>
                        {adverts.length ? adverts.map(advert => <AdvertCard details={advert} key={advert.id}/>) :
                            <h1>No Vehicles to display</h1>}
                    </Column>
                </Column>
            </main>
        );
    }
}

export const query = graphql`
  query {
    allSalesRentalsJson {
      edges {
        node {
          engineSize
          featureImage {
            relativeDirectory
            childImageSharp {
              fluid (maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          fuelType
          id
          mileage
          price
          title
          year
        }
      }
    }
    logo: file(relativePath: { eq: "images/test-centre/logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
    }
  }
`;

export default withSEO({
    title: pages.salesAndRentals.title
})(SalesAndRentalsPage);
