import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import Row from 'src/components/Row';

import css from './index.module.scss';


const ToggleSwitch = ({ activeSwitch, leftOption, middleOption, onChange, rightOption }) => {
  return (
    <Row className={css.switchFields} direction='cc'>
      <input id='leftOption' name='activeSwitch' type='radio' checked={activeSwitch === 'leftOption'} onChange={onChange} value='leftOption'/>
      <label htmlFor='leftOption'>{leftOption}</label>
        { middleOption != null ?
            <Fragment>
                <input id='middleOption' name='activeSwitch' type='radio' checked={activeSwitch === 'middleOption'} onChange={onChange} value='middleOption'/>
                <label htmlFor='middleOption'>{middleOption}</label>
            </Fragment> : ''
        }
      <input id='rightOption' name='activeSwitch' type='radio' checked={activeSwitch === 'rightOption'} onChange={onChange} value='rightOption'/>
      <label htmlFor='rightOption'>{rightOption}</label>
    </Row>
  );
};

ToggleSwitch.defaultProps = {
  activeSwitch: 'leftOption'
};

ToggleSwitch.propTypes = {
  activeSwitch: PropTypes.oneOf(['leftOption', 'middleOption', 'rightOption']),
  leftOption: PropTypes.string.isRequired,
  middleOption: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  rightOption: PropTypes.string.isRequired
};

export default ToggleSwitch;
