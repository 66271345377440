import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Img from 'gatsby-image'

import Card from 'src/components/Card';

import css from './index.module.scss';


const AdvertCard = props => {
  
  const {
    engineSize, featureImage, fuelType, id, mileage, price, title, year
  } = props.details;
  
  let tagLine = year ? year : '';
  tagLine += engineSize ? ' - ' + (engineSize % 1 === 0 ? engineSize + '.0' : engineSize) + 'L' : '';
  tagLine += fuelType ? (engineSize ? ' ' + fuelType : ' - ' + fuelType) : '';
  tagLine += mileage ? ' - ' + mileage : '';
  
  const basePath = featureImage.relativeDirectory.startsWith('images/sales-&-rentals/for-hire') ? '/commercials/sales-&-rentals/for-hire' : '/commercials/sales-&-rentals/for-sale';
  
  return (
    <Link className={css.advertCardRoot} to={`${basePath}/${id}`}>
      <Card className={css.advertCard}>
        <Img className={css.image} fluid={featureImage.childImageSharp.fluid}/>
        <p className={css.title}>{title + ' - ' + (price ? '€' + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '')}</p>
        <p>{tagLine}</p>
      </Card>
    </Link>
  )
};

AdvertCard.propTypes = {
  details: PropTypes.object.isRequired
};

export default AdvertCard;
